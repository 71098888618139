// Base
@import "../global-variables";
@import "../vendor/slick";

body {
    background-color: #fff;
}


// SECTION NAV

.section-nav {
    opacity: 0;
    position: fixed;
    right: calc(1rem - 3px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 15;
    transition: opacity .75s;

    @media (max-width: 1600px) {
        right: calc(.75rem - 3px);
    }

    &.active {
        opacity: 1;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0 0 .25rem;
        padding: 0;
        color: #000;

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            color: #fff;

            a {
                background-color: #E93D42;
            }
        }

        a {
            display: block;
            width: 4px;
            height: 47px;
            background-color: #C0C3C3;
        }
    }
}


// SECTION CONTAINER

.section-container {
    width: 100%;

    &--hero {
        transform: translate3d(0, 0, 0);

        .section-container__inner {
            background-color: #F5F5F5;
        }
    }

    &--1 {
        background-color: #F5F5F5;
        padding: 0 2rem 2rem;

        @media (max-width: 1199px) {
            padding: 0;
        }

        @media (max-width: 767px) {
            padding: 1.5rem;
        }

        .section-container__inner {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: none;
            margin: 0 auto;
            padding-left: 2rem;
            padding-right: 2rem;
            background-image: url(/images/webcontent/assets/home/2023-05/section-1-bg-fade.jpg);
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            
            @media (max-width: 991px) {
                padding: 20% 2rem 2rem;
                background-size: 1000px;
                background-position: 70% top;

                &.isIntersecting {
                    &::before {
                        background-color: transparent;
                    }
                }        
            }

            @media (max-width: 767px) {
                padding: 50% 1.5rem 0;
                background-size: 800px;
                background-position: 70% top;
            }

            @media (max-width: 450px) {
                padding: 60% 1.5rem 0;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,0);
                transition: background-color .3s;
            }
        }

        &.isIntersecting {
            .section-container__inner {

                &::before {
                    background-color: rgba(0,0,0,.35);

                    @media (max-width: 991px) {
                        background-color: transparent;
                    }
                }
            }
        }

    }

    &--2 {
        background-color: $blue;
        position: relative;
        overflow: hidden;
        
        .section-container__inner {
            position: relative;
            margin: 0 auto;
            padding: 0;

            @media (max-width: 767px) {
                padding: 0 2rem;
            }
        }
    }

    &--3 {
        position: relative;
        background-color: #F5F5F5;
        padding: 2rem 2rem 0;

        @media (max-width: 1600px) {
            padding: 1.5rem 1.5rem 0;
        }
    }

    &--4 {
        background-color: #F5F5F5;
        padding: 2rem;

        @media (max-width: 1600px) {
            padding: 1.5rem;
        }

        .section-container__inner {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: none;
            margin: 0 auto;
            padding: 14% 2rem 2rem;
            background-image: url(/images/webcontent/assets/home/2023-05/stats-hero.jpg?v=2);
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;

            @media (max-width: 991px) {
                padding: 25% 1rem 1rem;
                background-size: 1000px auto;
                background-position: center -15%;
            }

            @media (max-width: 767px) {
                padding: 50% 1rem 0;
            }


            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,0);
                transition: background-color .3s;
            }
        }

        &.isIntersecting {
            .section-container__inner {
                &::before {
                    background-color: rgba(0,0,0,.35);

                    @media (max-width: 991px) {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &--5 {
        background-image: url(/images/webcontent/assets/home/2023-05/testimonials-headshots-bg.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        
        .section-container__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 12% 4rem;

            @media (max-width: 1400px) {
                padding: 12% 1.5rem;
            }
        }
    }

    &--blog {
        background-image: url(/images/webcontent/assets/home/2023-05/blog-bg.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        .section-container__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 1400px;
            margin: 0 auto;
            padding: 10% 2rem;

            @media (max-width: 1400px) {
                padding: 10% 1.5rem;
            }
        }
    }

    &--lead-capture {
        background-image: url(/images/webcontent/assets/home/2023-05/lead-capture-bg.jpg);
        background-size: cover;
        background-position: center 33%;
        background-repeat: no-repeat;

        .section-container__inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            max-width: 1400px;
            margin: 0 auto;
            padding: 15% 2rem;

            @media (max-width: 991px) {
                padding: 25% 2rem 5%;
            }

            @media (max-width: 700px) {
                padding-top: 40%;
            }
        }
    }
}

// CONTENT CONTAINER

.content-container {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;

    &__content {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        padding-left: 2rem;

        @media (max-width: 991px) {
            padding-left: 0;
        }
    }
    
    &--hero {
        background-position: center center;

        &-2 {
            background-position: center top;
        }
        
        .content-container__content {
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding: 10% 4rem 10%;

            @media (max-width: 991px) {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            @media (max-width: 500px) {
                padding: 0;
            }
        }
    }

    &--launch-career {
        position: relative;
    
        .content-container__content {
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 30%;
            padding-bottom: 2rem;
    
            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                padding-left: 0;
                padding-top: 0;
            }

            @media (max-width: 767px) {
                margin-top: 25%;
            }
        }
    }

    &--grow-career {
        position: static;
    
        .content-container__content {
            position: static;
            justify-content: flex-start;
            align-items: center;
            padding: 18% 0 18% 4rem;

            @media (max-width: 991px) {
                padding: 25% 0;
            }
        }
    }

    &--succeed {
        position: relative;
        padding: 0 2rem;
        
        @media (max-width: 991px) {
            padding: 2rem 0 0;
        }

        @media (max-width: 767px) {
            background-size: auto;
            background-position: center -100%;
        }
        
        .content-container__content {
            justify-content: flex-start;
            align-items: center;
            padding: 9% 0 2rem;
    
            @media (max-width: 991px) {
                padding: 20% 1rem 0;
            }

            @media (min-width: 768px) and (max-width: 900px) {
                padding: 25% 1rem 0;
            }
            
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &--impact {
        position: relative;
        
        .content-container__content {
            justify-content: flex-start;
            align-items: center;

            @media (max-width: 767px) {
                padding: 2rem 0;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

// INLINE HERO IMG

.hero-inline-img {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;

    @media (min-width: 501px) {
        display: none;
    }
}

// SLIDER

.slider-cards-container {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    padding-left: 4rem;

    @media (max-width: 991px) {
        padding: 0 2rem;
    }

    @media (max-width: 767px) {
        width: 450px;
        padding: 2rem;
    }
}

.slider-cards {
    width: 100%;
    max-width: 750px;
    position: relative;
    margin-left: -.5rem;
    margin-bottom: -3rem;
    padding: 0 .5rem;
    transform: translateY(100%);
    opacity: 0;
    transition: opacity .2s, transform .5s;

    &.slick-initialized {
        opacity: 1;
        transform: translateY(-50%);

        @media (max-width: 991px) {
            transform: translateY(-25%);
            margin-bottom: -1rem;
        }
    
        @media (max-width: 767px) {
            transform: translateY(0);
            margin: 0 auto;
        }
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }
    
	.slick-dots {
		position: absolute;
		width: auto;
		bottom: -1.5rem;
		left: 0;
		margin: 0 0 0 .5rem;
		padding: 0;
		list-style: none;
		text-align: center;
		z-index: 100;
        display: flex;

		li {
			display: inline-block;
		}

		button {
			background-color: transparent;
			color: transparent;
			border-color: transparent;
			position: relative;
			width: 45px;
			height: 0;
			padding: 0;
			font-size: 0;
		}

		button::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 40px;
			height: 6px;
            background-color: #cecece;
            border-radius: 0;
		}
        
		button:hover::before, li.slick-active button::before,  li.cycle-pager-active button::before {
            background-color: #3FBFAD;
		}
	}
}

.slider-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    max-width: 750px;
    margin: 1rem auto 0 0;

    @media (max-width: 767px) {
        flex: 1;
        flex-direction: column;
        max-width: 450px;
        margin: 1rem auto;
        height: auto;
    }

    .slick-initialized & {
        display: flex !important;
    }

    &__img {
        display: block;
        padding: 0;
        width: 295px;
        min-height: 263px;
        height: auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 767px) {
            width: 100%;
            min-height: 0;
            background-image: none !important;
        }

        img {
            display: block;
            width: 100%;
            visibility: hidden;

            @media (max-width: 767px) {
                visibility: visible;
            }
        }
    }

    &__content {
        position: relative;
        width: calc(100% - 295px);
        padding: 3rem 2rem 2rem;
        background-color: $blue;

        @media (min-width: 768px) and (max-width: 991px) {
            padding: 2.5rem 1.5rem 1.5rem;
        }

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 1;
            justify-content: center;
        }
    }

    &__label {
        display: inline-block;
        position: absolute;
        padding: .5rem 1rem;
        top: 0;
        color: #fff;
        background-color: $green;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        border-radius: 2rem;
        transform: translateY(-50%);
        line-height: 1;
    }

    &__headline {
        margin-bottom: .5rem;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        color: #fff;

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 20px;
        }
    }

    &__description {
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #dadfe1;

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 14px;
        }
    }

    .ace-btn-primary {
        padding-top: .65rem;
        padding-bottom: .65rem;
    }
}


// CONTENT CARD

.content-card {
    width: 100%;
    max-width: 480px;
    padding: 2rem;
    background-color: #fff;

    @media (max-width: 1199px) {
        padding: 1.5rem;
        max-width: 400px;
    }

    @media (max-width: 991px) {
        max-width: 350px;
    }

    @media (max-width: 767px) {
        max-width: 400px;
    }

    &__title {
        font-size: 45px;
        font-weight: 400;
        line-height: 1.2;
        color: $blue;

        @media (max-width: 1199px) {
            font-size: 38px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        color: #373737;

        @media (max-width: 1199px) {
            font-size: 14px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        ul > li {
            font-weight: 700;
        }
    }

    &--home {
        margin: auto 0 3rem;

        @media (max-width: 767px) {
            max-width: 350px;
        }    

        @media (max-width: 500px) {
            margin: 0;
            max-width: 100%;
        }
    }

    &--launch-career {
        @media (min-width: 992px) {
            position: sticky;
            top: calc(1rem + 70px);
            margin-right: 1rem;
        }
        
        @media (min-width: 992px) and (max-width: 1250px) {
            width: auto;
            margin-right: 1rem;
        }

        @media (max-width: 991px) {
            margin: 0 auto 1rem 0;
            position: relative;
            transform: translateY(30%);
            opacity: 0;
            transition: transform .85s, opacity 1s;

            &.isIntersecting {
                transform: translateY(0);
                opacity: 1;
            }
        }

        @media (max-width: 767px) {
            margin-left: auto;
        }
    }

    &--grow-career {
        z-index: 12;

        @media (max-width: 991px) {
            margin: 0 auto 0 2rem;
        }

        @media (max-width: 767px) {
            margin-left: auto;
        }
    }

    &--succeed {
        margin-right: 2rem;
        z-index: 12;

        @media (min-width: 992px) {
            position: sticky;
            top: calc(1rem + 70px);
        }

        @media (max-width: 991px) {
            margin: 0 auto 0 0;
            transform: translateY(20%);
            opacity: 0;
            transition: transform .85s, opacity 1s;

            &.isIntersecting {
                transform: translateY(0);
                opacity: 1;
            }
        }

        @media (max-width: 767px) {
            margin: 10rem auto 0;
            margin-left: auto;
        }
    }

    &--impact {
        margin-right: 2rem;
        z-index: 12;

        @media (min-width: 992px) {
            position: sticky;
            top: calc(1rem + 70px);
        }

        @media (max-width: 991px) {
            align-self: flex-start;
            margin-left: .5rem;
            margin-bottom: 1rem;
            transform: translateY(10%);
            opacity: 0;
            transition: transform .85s, opacity 1s;

            &.isIntersecting {
                transform: translateY(0);
                opacity: 1;
            }
        }

        @media (max-width: 767px) {
            margin: 0 auto 1rem;

            &.isIntersecting {
                transform: translateY(0);
                opacity: 1;
                align-self: center;
            }
        }
    }

    &--lead-capture {
        opacity: 0;
        transform: scale(.3);
        transition: transform .5s, opacity .75s;

        .isIntersecting & {
            opacity: 1;
            transform: scale(1);
        }


        @media (max-width: 991px) {
            margin: 0 auto;
        }

        @media (max-width: 767px) {
            width: 100%;
            max-width: 480px;
        }
    }
}


// CERT CARDS

.cert-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;

    @media (max-width: 991px) {
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    
    @media (min-width: 768px) {
        transform: translateY(30%);
        opacity: 0;
        transition: transform .5s, opacity .75s;
        
        &.isIntersecting {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @media (min-width: 992px) {
        margin-top: 4rem;
    }


    &-column {
        @media (max-width: 991px) {
            width: calc(50% - .5rem);
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0;
            transform: translateY(30%);
            opacity: 0;
            transition: transform .85s, opacity 1s;

            .cert-cards.isIntersecting & {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &:last-child {
            margin-top: 4rem;


            @media (max-width: 767px) {
                margin-top: 0;
                padding: 0;
            }
        }
    }
}

.cert-card {
    width: 360px;
    margin: 1rem 1rem 2rem;
    position: relative;

    @media (min-width: 992px) and (max-width: 1250px) {
        width: 300px;
        margin: .5rem .5rem 1rem;  
    }

    @media (max-width: 991px) {
        width: 100%;
        margin: 1rem 0;
    }

    @media (max-width: 767px) {
        max-width: 400px;
        width: 100%;
        margin: 1rem 0 0;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 60% 0 0;
        position: relative;
        overflow: hidden;

        @media (min-width: 992px) and (max-width: 1250px) {
            padding-top: 65%;
        }
    }

    &__img {
        display: block;
        position: absolute;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        transform: translateY(-10%);
    }

    &__badge {
        display: block;
        position: absolute;
        width: 118px;
        left: 1rem;
        top: calc(25% + 2rem);

        @media (min-width: 992px) and (max-width: 1250px) {
            top: calc(25% + 1rem);
        }

        @media (max-width: 767px) {
            top: calc(25% + 3rem);
        }
    }

    &__body {
        padding: 2rem 1.5rem 1.5rem;
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        color: #fff;

        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }

    &__description {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
    }

    &--pt {
        .cert-card__body {
            background-color: $red;
        }
    }

    &--gfi {
        .cert-card__body {
            background-color: $blue;
        }
    }

    &--hc {
        .cert-card__body {
            background-color: $green;
        }
        .cert-card__header {
            background-position: center center;
        }
    }

    &--mes {
        .cert-card__body {
            background-color: $blue-med;
        }
    }
}


// CONED IMAGES

.coned-img {
    position: absolute;
    filter: blur(3px);
    transform-origin: top left;
    transform: scale(.2);
    width: 23vw;
    opacity: 0;
    transition: transform .5s, filter .5s, opacity .75s;

    .isIntersecting & {
        filter: blur(0) drop-shadow(2px 4px 6px black);
        opacity: 1;
    }

    @media (min-width: 1450px) {
        width: 350px;
        height: 350px;
        padding: 0;
    }

    &--1 {
        // 157
        top: 70%;
        left: 3%;
        z-index: 1;
        
        .isIntersecting & {
            transform: scale(.44);
        }
    }

    &--2 {
        // 210
        top: -5%;
        left: 10%;
        z-index: 2;
        
        .isIntersecting & {
            transform: scale(.6);
        }
    }

    &--3 {
        // 146
        top: 75%;
        left: 53%;
        z-index: 3;
        
        .isIntersecting & {
            transform: scale(.42);
        }
    }

    &--4 {
        // 232
        top: 45%;
        left: 55%;
        z-index: 4;
        
        .isIntersecting & {
            transform: scale(.66);
        }
    }

    &--5 {
        // 292
        top: 12%;
        left: -3%;
        z-index: 5;
        
        .isIntersecting & {
            transform: scale(.83);
        }
    }

    &--6 {
        // 160
        top: 5%;
        left: 27%;
        z-index: 6;
        
        .isIntersecting & {
            transform: scale(.45);
        }
    }

    &--7 {
        // 322
        top: 12%;
        left: 42%;
        z-index: 8;
        
        .isIntersecting & {
            transform: scale(.92);
        }
    }

    &--8 {
        // 155
        top: 17%;
        left: 70%;
        z-index: 7;
        
        .isIntersecting & {
            transform: scale(.44);
        }
    }

    &--9 {
        // 250
        top: 20%;
        right: -3%;
        z-index: 9;
        transform-origin: top right;
        
        .isIntersecting & {
            transform: scale(.71);
        }
    }

    &--10 {
        // 350
        bottom: 0;
        right: 0;
        z-index: 10;
        
        .isIntersecting & {
            transform: scale(1);
        }
    }

    &--11 {
        // 320
        bottom: 0;
        left: 30%;
        z-index: 11;
        transform-origin: bottom left;
        
        .isIntersecting & {
            transform: scale(.91);
        }
    }

    &--12 {
        // 220
        top: -5%;
        left: 60%;
        z-index: 7;
        
        .isIntersecting & {
            transform: scale(.62);
        }
    }
}


// SUCCESS IMAGES

.success-images {
    position: relative;
    padding: 1rem;
    overflow: hidden;

    &.isIntersecting {

        .success-images__item {
            opacity: 1;

            @media (max-width: 991px) {
                transform: translate(0, 0);
            }
    
            @media (min-width: 992px) {
                transform: translate(0, 10%);
            }
        }
    }

    &__item {
        display: block;
        max-width: 100%;
        transform: translate(35%, 35%);
        opacity: 0;
        transition: transform .5s, opacity .75s;

        @media (max-width: 991px) {
            max-width: 400px;
            width: 100%;
        }

        @media (min-width: 992px) {
            margin: 8rem 0 4rem auto;
            width: 70%;
        }
    }
}


// IMPACT ITEMS

.impact-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    position: relative;
    transform: translateY(30%);
    opacity: 0;
    transition: transform .5s, opacity .75s;

    @media (min-width: 992px) {
        max-width: 50%;
        margin: 10rem 0 6rem auto;
    }

    @media (max-width: 991px) {
        margin-top: 5rem;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    @media (max-width: 767px) {
        margin-top: 0;
    }

    &.isIntersecting {
        transform: translateY(0);
        opacity: 1;
    }

    &__item {
        width: calc(50% - 1rem);
        margin: .5rem;
        padding: .5rem;
        text-align: center;
        max-width: 300px;

        @media (max-width: 1400px) {
            max-width: 280px;
        }

        @media (max-width: 991px) {
            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: .5rem 0;
        }

        &-img {
            display: block;
            margin: 0 auto 1rem;

            @media (max-width: 991px) {
                margin: 0 auto;
                width: 80px;
            }
        }

        &-body {
            @media (max-width: 991px) {
                padding: .5rem;
                text-align: left;
            }
        }
        
        &-title {
            text-transform: uppercase;
            color: #fff;
            font-size: 30px;
            font-weight: 700;

            @media (max-width: 1199px) {
                font-size: 30px;
            }

            @media (max-width: 991px) {
                font-size: 26px;
            }
        }

        &-description {
            padding: 0 .5rem;
            color: #fff;
            font-size: 16px;
            font-weight: 400;

            @media (max-width: 1199px) {
                font-size: 14px;
            }

            @media (max-width: 991px) {
                margin: 0;
                padding: 0;
                font-size: 13px;
            }
        }

        &--pt {
            background-color: rgba(229, 61, 70, .9);
        }

        &--hc {
            background-color: rgba(74, 191, 173, .9);
        }

        &--gfi {
            background-color: rgba(2, 38, 60, .9);
        }

        &--mes {
            background-color: rgba(82, 130, 222, .9);
        }
    }
}

// TESTIMONIALS


$module: 'testimonial';

.#{$module} {
    &__slider-wrap {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 2rem;

        @media (max-width: 767px) {
            max-width: 500px;
            padding: 0 1rem;
        }
    }

    &__slider {
        display: block;
        opacity: 0;
        transition: opacity .3s;

        &.slick-initialized {
            opacity: 1;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    &__item {

    }

    &__slide-next,
    &__slide-prev {
        display: none;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 50%;
        font-size: 3rem;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1.14286;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background: transparent;
        z-index: 10;
        transform: translateY(-50%);
        
        @media (max-width: 1400px) {
            font-size: 2.5rem;
        }
        
        @media (max-width: 767px) {
            font-size: 2rem;
        }
        
        &.slick-arrow {
            display: inline-block;
        }
    }

    &__slide-prev {
        left: -1rem;

        @media (max-width: 1400px) {
            left: -.5rem;
        }
    }

    
    &__slide-next {
        right: -1rem;

        @media (max-width: 1400px) {
            right: -.5rem;
        }
    }
    
    &__slider-wrap {
        position: relative;
    }
    
    &__slider {
        &.slick-initialized {
            position: relative;
            display: block;
            box-sizing: border-box;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}

.testimonial {
    @media (min-width: 768px) and (max-width: 991px) {
        width: calc(50% - 2rem);
        margin: 0 .75rem;
    }

    @media (max-width: 767px) {
        padding: 0 .5rem;
    }

    &__inner {
        display: flex;
        margin: 0 auto;
        text-align: left;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        @media (max-width: 767px) {
            width: 100%;
            max-width: 450px;
        }    
    }

    &__img {
        display: block;
        width: calc((354/1204) * 100%);
        padding-top: calc((354/1204) * 100%);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        

        @media (max-width: 991px) {
            width: 100%;
            padding-top: 100%;
        }
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        padding: 2rem;
        background-image: url(/images/webcontent/assets/home/2023-05/testimonial-bg.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @media (max-width: 1400px) {
            padding: 1.5rem;
        }

        @media (max-width: 767px) {
            padding: 2rem 1.5rem;
        }

        &::before,
        &::after {
            display: block;
            width: 34px;
            height: 34px;
            content: '';
            position: absolute;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            @media (max-width: 767px) {
                width: 20px;
                height: 20px;
                padding: 0;
            }
        }

        &::before {
            left: 1.5rem;
            top: 1.5rem;
            background-image: url(/images/webcontent/assets/home/2023-05/quote-open-white.png);

            @media (max-width: 1400px) {
                left: .5rem;
                top: .5rem;
            }
        }

        &::after {
            right: 1.5rem;
            bottom: 1.5rem;
            background-image: url(/images/webcontent/assets/home/2023-05/quote-close-white.png);

            @media (max-width: 1400px) {
                right: .5rem;
                bottom: .5rem;
            }

            @media (max-width: 767px) {
                padding: 0;
            }
        }
    }

    &__quote {
        position: relative;
        margin-bottom: 1rem;
        padding: 2rem 2rem 0;
        text-align: left;
        font-size: 30px;
        font-weight: 400;
        font-style: italic;
        line-height: 1.1;
        color: $blue;

        @media (max-width: 1400px) {
            padding: 1.5rem 1.5rem 0;
            font-size: 26px;
        }

        @media (max-width: 991px) {
            font-size: 24px;
        }

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    &__name {
        margin-bottom: .2rem;
        padding: 0 2rem;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        color: $blue;

        @media (max-width: 1400px) {
            padding: 0 1.5rem;
            font-size: 18px;
        }

        @media (max-width: 991px) {
            font-size: 16px;
        }

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    &__title {
        margin-bottom: 0;
        padding: 0 2rem;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.2;
        color: $blue;

        @media (max-width: 1400px) {
            padding: 0 1.5rem;
            font-size: 13px;
        }

        @media (max-width: 991px) {
            font-size: 12px;
        }

        @media (max-width: 767px) {
            padding: 0;
        }
    }
}

// BLOG

.blog-slider-container {
    width: 100%;
    // max-width: 1400px;
    margin: 0 auto;
    padding: 0;

    @media (max-width: 991px) {
        max-width: 800px;
    }

    @media (max-width: 767px) {
        max-width: 450px;
    }

    @media (max-width: 400px) {
        max-width: 100%;
        padding: 0;
    }

    .sub-section__row {
        max-width: none;
    }

    .sub-section__post {
        @media (max-width: 500px) {
            padding: 0;
        }
    }

    .post-related--slide .post-related__img {
        @media (min-width: 1200px) {
            height: 190px;
        }
    }

    .sub-section__slider {
        padding: 0 .5rem;
        opacity: 0;
        transition: opacity .3s;

        &.slick-initialized {
            opacity: 1;
        }
    }

    .sub-section__slider-wrap {
        width: 100%;
        max-width: 1350px;
        margin: 0 auto;
        padding: 0 2rem;
    }

    .sub-section__slide-prev,
    .sub-section__slide-next {
        font-size: 3rem;
    
        svg {
            fill: #02263c;
        }

        @media (max-width: 1400px) {
            font-size: 2.5rem;
        }
    
        @media (max-width: 767px) {
            font-size: 2rem;
        }
    }
}


// LEAD CAPTURE

.lead-capture-form,
.subhero-lead-capture-form {

    &__row {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 1.5rem;

        @media (max-width: 500px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        
        .lead-capture-form__input-wrap,
        .subhero-lead-capture-form__input-wrap {
            display: block;
            flex: 1;

            @media (max-width: 500px) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        .lead-capture-form__submit,
        .subhero-lead-capture-form__submit {
            display: block;
            white-space: nowrap;

            @media (max-width: 500px) {
                width: 200px;
                max-width: 100%;
            }
        }
    }

    &__submit {

    }

    &__input-wrap {
        &.input-reveal-wrap {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            position: relative;

            label {
                position: absolute;
                left: 0;
                top: -1.25rem;
            }

            input {
                align-self: stretch;
                width: 100%;
                padding: .5rem;
            }
        }

        &--checkbox-agree {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 1rem;

            label {
                margin: 0;
                color: $gray;
                font-size: 13px;
                font-weight: 700;
                line-height: 1.2;
            }

            input[type=checkbox] {
                margin: 0 .5rem .2rem 0;
            }
        }
    }
}


// LEAD CAPTURE SUBHERO

.subhero-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 1400px;
    max-width: 100%;
    margin: -5.5rem auto 0;
    padding-left: 4rem;
    padding-bottom: 2rem;

    @media (max-width: 991px) {
        margin: 0 auto;
        padding: 2rem;
    }

    .slider-cards-container {
        margin: 0 auto 2rem;
        padding: 0 0 2rem;
        
        @media (min-width: 768px) {
            width: auto;
            margin: 0 0 3rem 0;
            padding: 0;
        }

        .slider-cards {
            margin-bottom: 0;

            @media (max-width: 991px) {
                max-width: 100%;
                margin: 0;
                padding: 0;
            }

            &.slick-initialized {
                transform: none;
            }
        }
    }

    .subhero-lead-capture-container {
        margin: 0 0 3rem;
        padding: 1.25rem;
        background-color: $gray-med;

        &__inner {
            max-width: 100%;
            margin: 0 auto;

            @media (max-width: 1280px) {
                width: 600px;
                text-align: center;
            }
        }

        @media (max-width: 1280px) {
            width: calc(100% - 4rem);
            margin-bottom: 1rem;
            padding: 1.5rem;
        }

        @media (max-width: 991px) {
            width: 100%;
        }
        
        @media (max-width: 767px) {
            margin: 0 auto;
        }
    }

    .subhero-lead-capture-headline {
        font-weight: 900;
        line-height: 1.4;

        @media (max-width: 1280px) {
            font-size: 1.5rem;
        }

        @media (max-width: 991px) {
            font-size: 1.3rem;
        }

        @media (max-width: 450px) {
            font-size: 1.1rem;
        }
    }
}

.subhero-lead-capture-form {
    @media (max-width: 1280px) {
        margin: 0 auto;
        max-width: 450px;
    }
    &__row {
        @media (max-width: 1280px) {
            margin: 0 auto;
            max-width: 380px;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        .subhero-lead-capture-form__input-wrap {
            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        .subhero-lead-capture-form__submit {
            @media (max-width: 767px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    &__submit {
        @media (min-width: 768px) {
            margin-left: 1rem;
        }
    }

    &__input-wrap {
        &--checkbox-agree {
            @media (max-width: 1280px) {
                justify-content: center;
            }
            @media (max-width: 767px) {
                margin: 2rem auto 0;
                max-width: 300px;
            }
        }
    }
}

.slick-slide {
    -webkit-backface-visibility: hidden;
}


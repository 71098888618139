/* Structure */
/* Forms */
/*
     _ _      _       _
 ___| (_) ___| | __  (_)___
/ __| | |/ __| |/ /  | / __|
\__ \ | | (__|   < _ | \__ \
|___/_|_|\___|_|\_(_)/ |___/
                   |__/

 Version: 1.6.0
  Author: Ken Wheeler
 Website: http://kenwheeler.github.io
    Docs: http://kenwheeler.github.io/slick
    Repo: http://github.com/kenwheeler/slick
  Issues: http://github.com/kenwheeler/slick/issues

 */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

body {
  background-color: #fff;
}

.section-nav {
  opacity: 0;
  position: fixed;
  right: calc(1rem - 3px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  transition: opacity 0.75s;
}
@media (max-width: 1600px) {
  .section-nav {
    right: calc(0.75rem - 3px);
  }
}
.section-nav.active {
  opacity: 1;
}
.section-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.section-nav li {
  margin: 0 0 0.25rem;
  padding: 0;
  color: #000;
}
.section-nav li:last-child {
  margin-bottom: 0;
}
.section-nav li.active {
  color: #fff;
}
.section-nav li.active a {
  background-color: #E93D42;
}
.section-nav li a {
  display: block;
  width: 4px;
  height: 47px;
  background-color: #C0C3C3;
}

.section-container {
  width: 100%;
}
.section-container--hero {
  transform: translate3d(0, 0, 0);
}
.section-container--hero .section-container__inner {
  background-color: #F5F5F5;
}
.section-container--1 {
  background-color: #F5F5F5;
  padding: 0 2rem 2rem;
}
@media (max-width: 1199px) {
  .section-container--1 {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .section-container--1 {
    padding: 1.5rem;
  }
}
.section-container--1 .section-container__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/section-1-bg-fade.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .section-container--1 .section-container__inner {
    padding: 20% 2rem 2rem;
    background-size: 1000px;
    background-position: 70% top;
  }
  .section-container--1 .section-container__inner.isIntersecting::before {
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .section-container--1 .section-container__inner {
    padding: 50% 1.5rem 0;
    background-size: 800px;
    background-position: 70% top;
  }
}
@media (max-width: 450px) {
  .section-container--1 .section-container__inner {
    padding: 60% 1.5rem 0;
  }
}
.section-container--1 .section-container__inner::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}
.section-container--1.isIntersecting .section-container__inner::before {
  background-color: rgba(0, 0, 0, 0.35);
}
@media (max-width: 991px) {
  .section-container--1.isIntersecting .section-container__inner::before {
    background-color: transparent;
  }
}
.section-container--2 {
  background-color: #02263c;
  position: relative;
  overflow: hidden;
}
.section-container--2 .section-container__inner {
  position: relative;
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 767px) {
  .section-container--2 .section-container__inner {
    padding: 0 2rem;
  }
}
.section-container--3 {
  position: relative;
  background-color: #F5F5F5;
  padding: 2rem 2rem 0;
}
@media (max-width: 1600px) {
  .section-container--3 {
    padding: 1.5rem 1.5rem 0;
  }
}
.section-container--4 {
  background-color: #F5F5F5;
  padding: 2rem;
}
@media (max-width: 1600px) {
  .section-container--4 {
    padding: 1.5rem;
  }
}
.section-container--4 .section-container__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  padding: 14% 2rem 2rem;
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/stats-hero.jpg?v=2);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .section-container--4 .section-container__inner {
    padding: 25% 1rem 1rem;
    background-size: 1000px auto;
    background-position: center -15%;
  }
}
@media (max-width: 767px) {
  .section-container--4 .section-container__inner {
    padding: 50% 1rem 0;
  }
}
.section-container--4 .section-container__inner::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}
.section-container--4.isIntersecting .section-container__inner::before {
  background-color: rgba(0, 0, 0, 0.35);
}
@media (max-width: 991px) {
  .section-container--4.isIntersecting .section-container__inner::before {
    background-color: transparent;
  }
}
.section-container--5 {
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/testimonials-headshots-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-container--5 .section-container__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12% 4rem;
}
@media (max-width: 1400px) {
  .section-container--5 .section-container__inner {
    padding: 12% 1.5rem;
  }
}
.section-container--blog {
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/blog-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-container--blog .section-container__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 10% 2rem;
}
@media (max-width: 1400px) {
  .section-container--blog .section-container__inner {
    padding: 10% 1.5rem;
  }
}
.section-container--lead-capture {
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/lead-capture-bg.jpg);
  background-size: cover;
  background-position: center 33%;
  background-repeat: no-repeat;
}
.section-container--lead-capture .section-container__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 15% 2rem;
}
@media (max-width: 991px) {
  .section-container--lead-capture .section-container__inner {
    padding: 25% 2rem 5%;
  }
}
@media (max-width: 700px) {
  .section-container--lead-capture .section-container__inner {
    padding-top: 40%;
  }
}

.content-container {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
.content-container__content {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 2rem;
}
@media (max-width: 991px) {
  .content-container__content {
    padding-left: 0;
  }
}
.content-container--hero {
  background-position: center center;
}
.content-container--hero-2 {
  background-position: center top;
}
.content-container--hero .content-container__content {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10% 4rem 10%;
}
@media (max-width: 991px) {
  .content-container--hero .content-container__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 500px) {
  .content-container--hero .content-container__content {
    padding: 0;
  }
}
.content-container--launch-career {
  position: relative;
}
.content-container--launch-career .content-container__content {
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 30%;
  padding-bottom: 2rem;
}
@media (max-width: 991px) {
  .content-container--launch-career .content-container__content {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .content-container--launch-career .content-container__content {
    margin-top: 25%;
  }
}
.content-container--grow-career {
  position: static;
}
.content-container--grow-career .content-container__content {
  position: static;
  justify-content: flex-start;
  align-items: center;
  padding: 18% 0 18% 4rem;
}
@media (max-width: 991px) {
  .content-container--grow-career .content-container__content {
    padding: 25% 0;
  }
}
.content-container--succeed {
  position: relative;
  padding: 0 2rem;
}
@media (max-width: 991px) {
  .content-container--succeed {
    padding: 2rem 0 0;
  }
}
@media (max-width: 767px) {
  .content-container--succeed {
    background-size: auto;
    background-position: center -100%;
  }
}
.content-container--succeed .content-container__content {
  justify-content: flex-start;
  align-items: center;
  padding: 9% 0 2rem;
}
@media (max-width: 991px) {
  .content-container--succeed .content-container__content {
    padding: 20% 1rem 0;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .content-container--succeed .content-container__content {
    padding: 25% 1rem 0;
  }
}
@media (max-width: 767px) {
  .content-container--succeed .content-container__content {
    flex-direction: column;
    align-items: center;
  }
}
.content-container--impact {
  position: relative;
}
.content-container--impact .content-container__content {
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 767px) {
  .content-container--impact .content-container__content {
    padding: 2rem 0;
    flex-direction: column;
    align-items: center;
  }
}

.hero-inline-img {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
}
@media (min-width: 501px) {
  .hero-inline-img {
    display: none;
  }
}

.slider-cards-container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-left: 4rem;
}
@media (max-width: 991px) {
  .slider-cards-container {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  .slider-cards-container {
    width: 450px;
    padding: 2rem;
  }
}

.slider-cards {
  width: 100%;
  max-width: 750px;
  position: relative;
  margin-left: -0.5rem;
  margin-bottom: -3rem;
  padding: 0 0.5rem;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.2s, transform 0.5s;
}
.slider-cards.slick-initialized {
  opacity: 1;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .slider-cards.slick-initialized {
    transform: translateY(-25%);
    margin-bottom: -1rem;
  }
}
@media (max-width: 767px) {
  .slider-cards.slick-initialized {
    transform: translateY(0);
    margin: 0 auto;
  }
}
.slider-cards .slick-track {
  display: flex;
  align-items: stretch;
}
.slider-cards .slick-dots {
  position: absolute;
  width: auto;
  bottom: -1.5rem;
  left: 0;
  margin: 0 0 0 0.5rem;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 100;
  display: flex;
}
.slider-cards .slick-dots li {
  display: inline-block;
}
.slider-cards .slick-dots button {
  background-color: transparent;
  color: transparent;
  border-color: transparent;
  position: relative;
  width: 45px;
  height: 0;
  padding: 0;
  font-size: 0;
}
.slider-cards .slick-dots button::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 6px;
  background-color: #cecece;
  border-radius: 0;
}
.slider-cards .slick-dots button:hover::before, .slider-cards .slick-dots li.slick-active button::before, .slider-cards .slick-dots li.cycle-pager-active button::before {
  background-color: #3FBFAD;
}

.slider-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 750px;
  margin: 1rem auto 0 0;
}
@media (max-width: 767px) {
  .slider-card {
    flex: 1;
    flex-direction: column;
    max-width: 450px;
    margin: 1rem auto;
    height: auto;
  }
}
.slick-initialized .slider-card {
  display: flex !important;
}
.slider-card__img {
  display: block;
  padding: 0;
  width: 295px;
  min-height: 263px;
  height: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .slider-card__img {
    width: 100%;
    min-height: 0;
    background-image: none !important;
  }
}
.slider-card__img img {
  display: block;
  width: 100%;
  visibility: hidden;
}
@media (max-width: 767px) {
  .slider-card__img img {
    visibility: visible;
  }
}
.slider-card__content {
  position: relative;
  width: calc(100% - 295px);
  padding: 3rem 2rem 2rem;
  background-color: #02263c;
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-card__content {
    padding: 2.5rem 1.5rem 1.5rem;
  }
}
@media (max-width: 767px) {
  .slider-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: center;
  }
}
.slider-card__label {
  display: inline-block;
  position: absolute;
  padding: 0.5rem 1rem;
  top: 0;
  color: #fff;
  background-color: #4abfad;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  border-radius: 2rem;
  transform: translateY(-50%);
  line-height: 1;
}
.slider-card__headline {
  margin-bottom: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-card__headline {
    font-size: 20px;
  }
}
.slider-card__description {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #dadfe1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-card__description {
    font-size: 14px;
  }
}
.slider-card .ace-btn-primary {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
}

.content-card {
  width: 100%;
  max-width: 480px;
  padding: 2rem;
  background-color: #fff;
}
@media (max-width: 1199px) {
  .content-card {
    padding: 1.5rem;
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .content-card {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .content-card {
    max-width: 400px;
  }
}
.content-card__title {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
  color: #02263c;
}
@media (max-width: 1199px) {
  .content-card__title {
    font-size: 38px;
  }
}
.content-card__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #373737;
}
@media (max-width: 1199px) {
  .content-card__description {
    font-size: 14px;
  }
}
.content-card__description:last-child {
  margin-bottom: 0;
}
.content-card__description ul > li {
  font-weight: 700;
}
.content-card--home {
  margin: auto 0 3rem;
}
@media (max-width: 767px) {
  .content-card--home {
    max-width: 350px;
  }
}
@media (max-width: 500px) {
  .content-card--home {
    margin: 0;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .content-card--launch-career {
    position: sticky;
    top: calc(1rem + 70px);
    margin-right: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1250px) {
  .content-card--launch-career {
    width: auto;
    margin-right: 1rem;
  }
}
@media (max-width: 991px) {
  .content-card--launch-career {
    margin: 0 auto 1rem 0;
    position: relative;
    transform: translateY(30%);
    opacity: 0;
    transition: transform 0.85s, opacity 1s;
  }
  .content-card--launch-career.isIntersecting {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .content-card--launch-career {
    margin-left: auto;
  }
}
.content-card--grow-career {
  z-index: 12;
}
@media (max-width: 991px) {
  .content-card--grow-career {
    margin: 0 auto 0 2rem;
  }
}
@media (max-width: 767px) {
  .content-card--grow-career {
    margin-left: auto;
  }
}
.content-card--succeed {
  margin-right: 2rem;
  z-index: 12;
}
@media (min-width: 992px) {
  .content-card--succeed {
    position: sticky;
    top: calc(1rem + 70px);
  }
}
@media (max-width: 991px) {
  .content-card--succeed {
    margin: 0 auto 0 0;
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.85s, opacity 1s;
  }
  .content-card--succeed.isIntersecting {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .content-card--succeed {
    margin: 10rem auto 0;
    margin-left: auto;
  }
}
.content-card--impact {
  margin-right: 2rem;
  z-index: 12;
}
@media (min-width: 992px) {
  .content-card--impact {
    position: sticky;
    top: calc(1rem + 70px);
  }
}
@media (max-width: 991px) {
  .content-card--impact {
    align-self: flex-start;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    transform: translateY(10%);
    opacity: 0;
    transition: transform 0.85s, opacity 1s;
  }
  .content-card--impact.isIntersecting {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .content-card--impact {
    margin: 0 auto 1rem;
  }
  .content-card--impact.isIntersecting {
    transform: translateY(0);
    opacity: 1;
    align-self: center;
  }
}
.content-card--lead-capture {
  opacity: 0;
  transform: scale(0.3);
  transition: transform 0.5s, opacity 0.75s;
}
.isIntersecting .content-card--lead-capture {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 991px) {
  .content-card--lead-capture {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .content-card--lead-capture {
    width: 100%;
    max-width: 480px;
  }
}

.cert-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
}
@media (max-width: 991px) {
  .cert-cards {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .cert-cards {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (min-width: 768px) {
  .cert-cards {
    transform: translateY(30%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.75s;
  }
  .cert-cards.isIntersecting {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .cert-cards {
    margin-top: 4rem;
  }
}
@media (max-width: 991px) {
  .cert-cards-column {
    width: calc(50% - 0.5rem);
  }
}
@media (max-width: 767px) {
  .cert-cards-column {
    width: 100%;
    padding: 0;
    transform: translateY(30%);
    opacity: 0;
    transition: transform 0.85s, opacity 1s;
  }
  .cert-cards.isIntersecting .cert-cards-column {
    transform: translateY(0);
    opacity: 1;
  }
}
.cert-cards-column:last-child {
  margin-top: 4rem;
}
@media (max-width: 767px) {
  .cert-cards-column:last-child {
    margin-top: 0;
    padding: 0;
  }
}

.cert-card {
  width: 360px;
  margin: 1rem 1rem 2rem;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1250px) {
  .cert-card {
    width: 300px;
    margin: 0.5rem 0.5rem 1rem;
  }
}
@media (max-width: 991px) {
  .cert-card {
    width: 100%;
    margin: 1rem 0;
  }
}
@media (max-width: 767px) {
  .cert-card {
    max-width: 400px;
    width: 100%;
    margin: 1rem 0 0;
  }
}
.cert-card__header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 60% 0 0;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1250px) {
  .cert-card__header {
    padding-top: 65%;
  }
}
.cert-card__img {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  transform: translateY(-10%);
}
.cert-card__badge {
  display: block;
  position: absolute;
  width: 118px;
  left: 1rem;
  top: calc(25% + 2rem);
}
@media (min-width: 992px) and (max-width: 1250px) {
  .cert-card__badge {
    top: calc(25% + 1rem);
  }
}
@media (max-width: 767px) {
  .cert-card__badge {
    top: calc(25% + 3rem);
  }
}
.cert-card__body {
  padding: 2rem 1.5rem 1.5rem;
}
.cert-card__title {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}
.cert-card__title:hover {
  color: #fff;
  text-decoration: underline;
}
.cert-card__description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.cert-card--pt .cert-card__body {
  background-color: #e53d46;
}
.cert-card--gfi .cert-card__body {
  background-color: #02263c;
}
.cert-card--hc .cert-card__body {
  background-color: #4abfad;
}
.cert-card--hc .cert-card__header {
  background-position: center center;
}
.cert-card--mes .cert-card__body {
  background-color: #5282de;
}

.coned-img {
  position: absolute;
  filter: blur(3px);
  transform-origin: top left;
  transform: scale(0.2);
  width: 23vw;
  opacity: 0;
  transition: transform 0.5s, filter 0.5s, opacity 0.75s;
}
.isIntersecting .coned-img {
  filter: blur(0) drop-shadow(2px 4px 6px black);
  opacity: 1;
}
@media (min-width: 1450px) {
  .coned-img {
    width: 350px;
    height: 350px;
    padding: 0;
  }
}
.coned-img--1 {
  top: 70%;
  left: 3%;
  z-index: 1;
}
.isIntersecting .coned-img--1 {
  transform: scale(0.44);
}
.coned-img--2 {
  top: -5%;
  left: 10%;
  z-index: 2;
}
.isIntersecting .coned-img--2 {
  transform: scale(0.6);
}
.coned-img--3 {
  top: 75%;
  left: 53%;
  z-index: 3;
}
.isIntersecting .coned-img--3 {
  transform: scale(0.42);
}
.coned-img--4 {
  top: 45%;
  left: 55%;
  z-index: 4;
}
.isIntersecting .coned-img--4 {
  transform: scale(0.66);
}
.coned-img--5 {
  top: 12%;
  left: -3%;
  z-index: 5;
}
.isIntersecting .coned-img--5 {
  transform: scale(0.83);
}
.coned-img--6 {
  top: 5%;
  left: 27%;
  z-index: 6;
}
.isIntersecting .coned-img--6 {
  transform: scale(0.45);
}
.coned-img--7 {
  top: 12%;
  left: 42%;
  z-index: 8;
}
.isIntersecting .coned-img--7 {
  transform: scale(0.92);
}
.coned-img--8 {
  top: 17%;
  left: 70%;
  z-index: 7;
}
.isIntersecting .coned-img--8 {
  transform: scale(0.44);
}
.coned-img--9 {
  top: 20%;
  right: -3%;
  z-index: 9;
  transform-origin: top right;
}
.isIntersecting .coned-img--9 {
  transform: scale(0.71);
}
.coned-img--10 {
  bottom: 0;
  right: 0;
  z-index: 10;
}
.isIntersecting .coned-img--10 {
  transform: scale(1);
}
.coned-img--11 {
  bottom: 0;
  left: 30%;
  z-index: 11;
  transform-origin: bottom left;
}
.isIntersecting .coned-img--11 {
  transform: scale(0.91);
}
.coned-img--12 {
  top: -5%;
  left: 60%;
  z-index: 7;
}
.isIntersecting .coned-img--12 {
  transform: scale(0.62);
}

.success-images {
  position: relative;
  padding: 1rem;
  overflow: hidden;
}
.success-images.isIntersecting .success-images__item {
  opacity: 1;
}
@media (max-width: 991px) {
  .success-images.isIntersecting .success-images__item {
    transform: translate(0, 0);
  }
}
@media (min-width: 992px) {
  .success-images.isIntersecting .success-images__item {
    transform: translate(0, 10%);
  }
}
.success-images__item {
  display: block;
  max-width: 100%;
  transform: translate(35%, 35%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.75s;
}
@media (max-width: 991px) {
  .success-images__item {
    max-width: 400px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .success-images__item {
    margin: 8rem 0 4rem auto;
    width: 70%;
  }
}

.impact-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  position: relative;
  transform: translateY(30%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.75s;
}
@media (min-width: 992px) {
  .impact-items {
    max-width: 50%;
    margin: 10rem 0 6rem auto;
  }
}
@media (max-width: 991px) {
  .impact-items {
    margin-top: 5rem;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .impact-items {
    margin-top: 0;
  }
}
.impact-items.isIntersecting {
  transform: translateY(0);
  opacity: 1;
}
.impact-items__item {
  width: calc(50% - 1rem);
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  max-width: 300px;
}
@media (max-width: 1400px) {
  .impact-items__item {
    max-width: 280px;
  }
}
@media (max-width: 991px) {
  .impact-items__item {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem 0;
  }
}
.impact-items__item-img {
  display: block;
  margin: 0 auto 1rem;
}
@media (max-width: 991px) {
  .impact-items__item-img {
    margin: 0 auto;
    width: 80px;
  }
}
@media (max-width: 991px) {
  .impact-items__item-body {
    padding: 0.5rem;
    text-align: left;
  }
}
.impact-items__item-title {
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
@media (max-width: 1199px) {
  .impact-items__item-title {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .impact-items__item-title {
    font-size: 26px;
  }
}
.impact-items__item-description {
  padding: 0 0.5rem;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 1199px) {
  .impact-items__item-description {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .impact-items__item-description {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
}
.impact-items__item--pt {
  background-color: rgba(229, 61, 70, 0.9);
}
.impact-items__item--hc {
  background-color: rgba(74, 191, 173, 0.9);
}
.impact-items__item--gfi {
  background-color: rgba(2, 38, 60, 0.9);
}
.impact-items__item--mes {
  background-color: rgba(82, 130, 222, 0.9);
}

.testimonial__slider-wrap {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
}
@media (max-width: 767px) {
  .testimonial__slider-wrap {
    max-width: 500px;
    padding: 0 1rem;
  }
}
.testimonial__slider {
  display: block;
  opacity: 0;
  transition: opacity 0.3s;
}
.testimonial__slider.slick-initialized {
  opacity: 1;
}
.testimonial__slider .slick-track {
  display: flex;
  align-items: stretch;
}
.testimonial__slide-next, .testimonial__slide-prev {
  display: none;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 50%;
  font-size: 3rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.14286;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  z-index: 10;
  transform: translateY(-50%);
}
@media (max-width: 1400px) {
  .testimonial__slide-next, .testimonial__slide-prev {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px) {
  .testimonial__slide-next, .testimonial__slide-prev {
    font-size: 2rem;
  }
}
.testimonial__slide-next.slick-arrow, .testimonial__slide-prev.slick-arrow {
  display: inline-block;
}
.testimonial__slide-prev {
  left: -1rem;
}
@media (max-width: 1400px) {
  .testimonial__slide-prev {
    left: -0.5rem;
  }
}
.testimonial__slide-next {
  right: -1rem;
}
@media (max-width: 1400px) {
  .testimonial__slide-next {
    right: -0.5rem;
  }
}
.testimonial__slider-wrap {
  position: relative;
}
.testimonial__slider.slick-initialized {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  .testimonial {
    width: calc(50% - 2rem);
    margin: 0 0.75rem;
  }
}
@media (max-width: 767px) {
  .testimonial {
    padding: 0 0.5rem;
  }
}
.testimonial__inner {
  display: flex;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 991px) {
  .testimonial__inner {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .testimonial__inner {
    width: 100%;
    max-width: 450px;
  }
}
.testimonial__img {
  display: block;
  width: 29.4019933555%;
  padding-top: 29.4019933555%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .testimonial__img {
    width: 100%;
    padding-top: 100%;
  }
}
.testimonial__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 2rem;
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/testimonial-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 1400px) {
  .testimonial__body {
    padding: 1.5rem;
  }
}
@media (max-width: 767px) {
  .testimonial__body {
    padding: 2rem 1.5rem;
  }
}
.testimonial__body::before, .testimonial__body::after {
  display: block;
  width: 34px;
  height: 34px;
  content: "";
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .testimonial__body::before, .testimonial__body::after {
    width: 20px;
    height: 20px;
    padding: 0;
  }
}
.testimonial__body::before {
  left: 1.5rem;
  top: 1.5rem;
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/quote-open-white.png);
}
@media (max-width: 1400px) {
  .testimonial__body::before {
    left: 0.5rem;
    top: 0.5rem;
  }
}
.testimonial__body::after {
  right: 1.5rem;
  bottom: 1.5rem;
  background-image: url(https://ik.imagekit.io/02fmeo4exvw/assets/home/2023-05/quote-close-white.png);
}
@media (max-width: 1400px) {
  .testimonial__body::after {
    right: 0.5rem;
    bottom: 0.5rem;
  }
}
@media (max-width: 767px) {
  .testimonial__body::after {
    padding: 0;
  }
}
.testimonial__quote {
  position: relative;
  margin-bottom: 1rem;
  padding: 2rem 2rem 0;
  text-align: left;
  font-size: 30px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1;
  color: #02263c;
}
@media (max-width: 1400px) {
  .testimonial__quote {
    padding: 1.5rem 1.5rem 0;
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .testimonial__quote {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .testimonial__quote {
    padding: 0;
  }
}
.testimonial__name {
  margin-bottom: 0.2rem;
  padding: 0 2rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: #02263c;
}
@media (max-width: 1400px) {
  .testimonial__name {
    padding: 0 1.5rem;
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .testimonial__name {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .testimonial__name {
    padding: 0;
  }
}
.testimonial__title {
  margin-bottom: 0;
  padding: 0 2rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: #02263c;
}
@media (max-width: 1400px) {
  .testimonial__title {
    padding: 0 1.5rem;
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .testimonial__title {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .testimonial__title {
    padding: 0;
  }
}

.blog-slider-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 991px) {
  .blog-slider-container {
    max-width: 800px;
  }
}
@media (max-width: 767px) {
  .blog-slider-container {
    max-width: 450px;
  }
}
@media (max-width: 400px) {
  .blog-slider-container {
    max-width: 100%;
    padding: 0;
  }
}
.blog-slider-container .sub-section__row {
  max-width: none;
}
@media (max-width: 500px) {
  .blog-slider-container .sub-section__post {
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .blog-slider-container .post-related--slide .post-related__img {
    height: 190px;
  }
}
.blog-slider-container .sub-section__slider {
  padding: 0 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
}
.blog-slider-container .sub-section__slider.slick-initialized {
  opacity: 1;
}
.blog-slider-container .sub-section__slider-wrap {
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 2rem;
}
.blog-slider-container .sub-section__slide-prev,
.blog-slider-container .sub-section__slide-next {
  font-size: 3rem;
}
.blog-slider-container .sub-section__slide-prev svg,
.blog-slider-container .sub-section__slide-next svg {
  fill: #02263c;
}
@media (max-width: 1400px) {
  .blog-slider-container .sub-section__slide-prev,
  .blog-slider-container .sub-section__slide-next {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px) {
  .blog-slider-container .sub-section__slide-prev,
  .blog-slider-container .sub-section__slide-next {
    font-size: 2rem;
  }
}

.lead-capture-form__row,
.subhero-lead-capture-form__row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 1.5rem;
}
@media (max-width: 500px) {
  .lead-capture-form__row,
  .subhero-lead-capture-form__row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.lead-capture-form__row .lead-capture-form__input-wrap,
.lead-capture-form__row .subhero-lead-capture-form__input-wrap,
.subhero-lead-capture-form__row .lead-capture-form__input-wrap,
.subhero-lead-capture-form__row .subhero-lead-capture-form__input-wrap {
  display: block;
  flex: 1;
}
@media (max-width: 500px) {
  .lead-capture-form__row .lead-capture-form__input-wrap,
  .lead-capture-form__row .subhero-lead-capture-form__input-wrap,
  .subhero-lead-capture-form__row .lead-capture-form__input-wrap,
  .subhero-lead-capture-form__row .subhero-lead-capture-form__input-wrap {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.lead-capture-form__row .lead-capture-form__submit,
.lead-capture-form__row .subhero-lead-capture-form__submit,
.subhero-lead-capture-form__row .lead-capture-form__submit,
.subhero-lead-capture-form__row .subhero-lead-capture-form__submit {
  display: block;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .lead-capture-form__row .lead-capture-form__submit,
  .lead-capture-form__row .subhero-lead-capture-form__submit,
  .subhero-lead-capture-form__row .lead-capture-form__submit,
  .subhero-lead-capture-form__row .subhero-lead-capture-form__submit {
    width: 200px;
    max-width: 100%;
  }
}
.lead-capture-form__input-wrap.input-reveal-wrap,
.subhero-lead-capture-form__input-wrap.input-reveal-wrap {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
}
.lead-capture-form__input-wrap.input-reveal-wrap label,
.subhero-lead-capture-form__input-wrap.input-reveal-wrap label {
  position: absolute;
  left: 0;
  top: -1.25rem;
}
.lead-capture-form__input-wrap.input-reveal-wrap input,
.subhero-lead-capture-form__input-wrap.input-reveal-wrap input {
  align-self: stretch;
  width: 100%;
  padding: 0.5rem;
}
.lead-capture-form__input-wrap--checkbox-agree,
.subhero-lead-capture-form__input-wrap--checkbox-agree {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}
.lead-capture-form__input-wrap--checkbox-agree label,
.subhero-lead-capture-form__input-wrap--checkbox-agree label {
  margin: 0;
  color: #5f6567;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
}
.lead-capture-form__input-wrap--checkbox-agree input[type=checkbox],
.subhero-lead-capture-form__input-wrap--checkbox-agree input[type=checkbox] {
  margin: 0 0.5rem 0.2rem 0;
}

.subhero-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 1400px;
  max-width: 100%;
  margin: -5.5rem auto 0;
  padding-left: 4rem;
  padding-bottom: 2rem;
}
@media (max-width: 991px) {
  .subhero-container {
    margin: 0 auto;
    padding: 2rem;
  }
}
.subhero-container .slider-cards-container {
  margin: 0 auto 2rem;
  padding: 0 0 2rem;
}
@media (min-width: 768px) {
  .subhero-container .slider-cards-container {
    width: auto;
    margin: 0 0 3rem 0;
    padding: 0;
  }
}
.subhero-container .slider-cards-container .slider-cards {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .subhero-container .slider-cards-container .slider-cards {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}
.subhero-container .slider-cards-container .slider-cards.slick-initialized {
  transform: none;
}
.subhero-container .subhero-lead-capture-container {
  margin: 0 0 3rem;
  padding: 1.25rem;
  background-color: #dadfe1;
}
.subhero-container .subhero-lead-capture-container__inner {
  max-width: 100%;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .subhero-container .subhero-lead-capture-container__inner {
    width: 600px;
    text-align: center;
  }
}
@media (max-width: 1280px) {
  .subhero-container .subhero-lead-capture-container {
    width: calc(100% - 4rem);
    margin-bottom: 1rem;
    padding: 1.5rem;
  }
}
@media (max-width: 991px) {
  .subhero-container .subhero-lead-capture-container {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .subhero-container .subhero-lead-capture-container {
    margin: 0 auto;
  }
}
.subhero-container .subhero-lead-capture-headline {
  font-weight: 900;
  line-height: 1.4;
}
@media (max-width: 1280px) {
  .subhero-container .subhero-lead-capture-headline {
    font-size: 1.5rem;
  }
}
@media (max-width: 991px) {
  .subhero-container .subhero-lead-capture-headline {
    font-size: 1.3rem;
  }
}
@media (max-width: 450px) {
  .subhero-container .subhero-lead-capture-headline {
    font-size: 1.1rem;
  }
}

@media (max-width: 1280px) {
  .subhero-lead-capture-form {
    margin: 0 auto;
    max-width: 450px;
  }
}
@media (max-width: 1280px) {
  .subhero-lead-capture-form__row {
    margin: 0 auto;
    max-width: 380px;
  }
}
@media (max-width: 767px) {
  .subhero-lead-capture-form__row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .subhero-lead-capture-form__row .subhero-lead-capture-form__input-wrap {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .subhero-lead-capture-form__row .subhero-lead-capture-form__submit {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .subhero-lead-capture-form__submit {
    margin-left: 1rem;
  }
}
@media (max-width: 1280px) {
  .subhero-lead-capture-form__input-wrap--checkbox-agree {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .subhero-lead-capture-form__input-wrap--checkbox-agree {
    margin: 2rem auto 0;
    max-width: 300px;
  }
}

.slick-slide {
  -webkit-backface-visibility: hidden;
}